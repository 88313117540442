import React, { useCallback } from "react";
import { Container, Box, Typography, Grid2, Button } from "@mui/material";
import Navbar from "../../components/main/navbar";
import Footer from "../../components/main/footer";
import { loadStripe } from "@stripe/stripe-js";
import hostname from "../../hostname";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const OffersPage = () => {
  const offers = [
    {
      title: "5 cover letter",
      price: "FREE",
      id: "price_1QVdYUCOl27OIxPKrsSiZrRn",
      description: "Test Pack",
      color: "#342DBF",
      gradient: "linear-gradient(135deg, #342DBF, #5C4FBF)",
      presentation:
        "Try SmartCandidate with 5 free personalized cover letters to discover its potential. See how it simplifies your job applications with tailored cover letters.",
    },
    {
      title: "15 cover letter",
      price: 2.99,
      id: "price_1QUGXfCOl27OIxPK4rAEXThg",
      description: "Starter Pack",
      color: "#9D1CB9",
      gradient: "linear-gradient(135deg, #9D1CB9, #B04FC3)",
      presentation:
        "Perfect for quick job applications, the Starter Pack includes 15 personalized cover letters for just $2.99. Ideal for those who need a simple and efficient solution.",
    },
    {
      title: "30 cover letter",
      price: 4.99,
      id: "price_1QUGaICOl27OIxPKhyOVKcIm",
      description: "Standard Pack",
      color: "#A41243",
      gradient: "linear-gradient(135deg, #A41243, #C03F5A)",
      presentation:
        "The Standard Pack offers 30 tailored cover letters for only $4.99. Designed for those applying to multiple jobs, it’s the perfect balance of value and flexibility.",
    },
    {
      title: "50 cover letter",
      price: 7.99,
      id: "price_1QUGbLCOl27OIxPKHUot9Fdd",
      description: "Pro Pack",
      color: "#EC831C",
      gradient: "linear-gradient(135deg, #EC831C, #E68827)",
      presentation:
        "For regular job seekers, the Pro Pack provides 50 customized cover letters at $7.99. A comprehensive solution to keep your applications impactful.",
    },
  ];

  const redirectToLogin = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };

  const handleRefreshToken = useCallback(async () => {
    try {
      const response = await fetch(`${hostname}/api/v1/user/auth/refresh`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (response.ok) {
        const { accessToken } = await response.json();
        localStorage.setItem("accessToken", accessToken);
        return accessToken;
      } else {
        redirectToLogin();
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      redirectToLogin();
    }
  }, []);

  const handleChoose = useCallback(
    async (priceId) => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
          redirectToLogin();
          return;
        }
        let response = await fetch(
          `${hostname}/api/v1/user/payment/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ priceId }),
          }
        );

        if (response.status === 401 || response.status === 403) {
          const newAccessToken = await handleRefreshToken();
          if (newAccessToken) {
            response = await fetch(
              `${hostname}/api/v1/user/payment/create-checkout-session`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${newAccessToken}`,
                },
                body: JSON.stringify({ priceId }),
              }
            );
          } else {
            throw new Error("Failed to refresh token");
          }
        }

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.message);
        }

        const session = await response.json();

        if (session.id) {
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({ sessionId: session.id });
        }
      } catch (error) {
        console.error("Error creating checkout session:", error);
      }
    },
    [handleRefreshToken]
  );

  return (
    <>
      <Navbar />
      <Grid2 padding="50px">
        <Container>
          <Typography
            textAlign="center"
            variant="h4"
            gutterBottom
            fontWeight="700"
          >
            Choose your plan
          </Typography>
          <Typography
            textAlign="center"
            variant="body2"
            gutterBottom
            fontWeight="700"
            marginBottom="10px"
          >
            Not a subscription, Pay per use !
          </Typography>
          <Grid2 container spacing={3} justifyContent="space-evenly">
            {offers.map((offer) => (
              <Grid2 item key={offer.id} xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    height: "430px",
                    background: offer.gradient,
                    borderRadius: 4,
                    padding: "30px",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    color: "white",
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                    {offer.title}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                    }}
                  >
                    ${offer.price}
                  </Typography>

                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    {offer.description}
                  </Typography>
                  <Grid2 container justifyContent="center" display="flex">
                    <Typography
                      variant="body2"
                      sx={{
                        maxWidth: "200px",
                        color: "white",
                        marginBottom: "20px",
                      }}
                    >
                      {offer.presentation}
                    </Typography>
                  </Grid2>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 1,
                      mb: 2,
                      padding: "10px 25px 10px 25px",
                      fontWeight: "700",
                      color: offer.color,
                      backgroundColor: "white",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                    onClick={() => handleChoose(offer.id)}
                  >
                    Choose
                  </Button>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Container>
      </Grid2>
      <Footer />
    </>
  );
};

export default OffersPage;
