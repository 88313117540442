import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Audiotrack, LinkedIn, Instagram, X } from "@mui/icons-material";
import { Box, Grid2 } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        color: "white",
        backgroundColor: "#5271ff",
        padding: "70px",
      }}
    >
      <Container maxWidth="lg">
        <Grid2 container spacing={5}>
          <Grid2 item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="white"
              gutterBottom
              fontWeight="700"
            >
              Who are we?
            </Typography>
            <Typography
              variant="body2"
              color="white"
              maxWidth="600px"
              flexWrap="wrap"
              display="flex"
            >
              SmartCandidate is an intelligent application designed to create
              tailor-made cover letters that meet the specific requirements of
              each job. It helps candidates customize their applications based
              on their experiences and the expectations of recruiters. With
              SmartCandidate, prepare perfectly aligned application documents
              and boost your chances of success.
            </Typography>
            <Typography
              marginTop="3px"
              variant="body2"
              color="white"
              fontWeight="700"
            >
              <Link color="inherit" href="/terms">
                Terms and Conditions of Use
              </Link>
            </Typography>
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="white"
              gutterBottom
              fontWeight="700"
            >
              Contact Us!
            </Typography>
            <Typography variant="body2" color="white">
              France
            </Typography>
            <Typography variant="body2" color="white">
              smartcandidate.ai@gmail.com
            </Typography>
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="white"
              gutterBottom
              fontWeight="700"
            >
              Follow Us!
            </Typography>
            <Link
              href="https://x.com/smartcanidate"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <X />
            </Link>
            <Link
              href="https://www.instagram.com/smartcandidate/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link
              href="https://www.tiktok.com/@smartcandidate"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <Audiotrack />
            </Link>
            <Link
              href="https://www.linkedin.com/company/smartcandidate"
              color="inherit"
            >
              <LinkedIn />
            </Link>
          </Grid2>
        </Grid2>
        <Box mt={5}>
          <Typography
            variant="body2"
            color="white"
            align="center"
            fontWeight="700"
          >
            {"Copyright © "}
            <Link color="inherit" href="/">
              SmartCandidate
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
