import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Navbar from "../../../components/main/navbar";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Footer from "../../../components/main/footer";
import { useNavigate } from "react-router-dom";
import {
  Grid2,
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import hostname from "../../../hostname";

const defaultTheme = createTheme();

export default function ContactUs() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    mail: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${hostname}/api/v1/user/contact/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        navigate("mail-sent");
      } else {
        navigate("mail-not-sent");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Navbar />
      <Grid2
        container
        component="main"
        justifyContent="center"
        bgcolor="#f8f9fc"
      >
        <Grid2
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
          boxShadow="none"
          bgcolor="#f8f9fc"
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#5271ff" }}>
              <SendOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Contact
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                name="name"
                label="name"
                type="name"
                id="name"
                onChange={handleChange}
                value={formData.name}
              />
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                id="mail"
                value={formData.mail}
                label="mail"
                name="mail"
                autoComplete="mail"
                autoFocus
                onChange={handleChange}
              />
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                required
                id="message"
                name="message"
                margin="normal"
                label="message"
                value={formData.message}
                autoFocus
                fullWidth
                multiline
                onChange={handleChange}
                minRows={5}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Grid2>
      </Grid2>
      <Footer />
    </ThemeProvider>
  );
}
