import React from "react";
import Home from "./pages/home/landingPage/landingPage";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContactUs from "./pages/home/landingPage/contactUs";
import TermsOfUse from "./pages/home/landingPage/terms-of-use";
import AboutUs from "./pages/home/landingPage/aboutUs";
import Demo from "./pages/home/landingPage/demo";
import MailSent from "./pages/home/landingPage/messageSent";
import MailNotSent from "./pages/home/landingPage/messageNotSent";
import OffersPage from "./pages/offer/offer";
import Login from "./pages/home/log/login";
import Signup from "./pages/home/log/signup";
import MailValidation from "./pages/home/log/mail_validation";
import SuccessSignup from "./pages/home/log/success_signup";
import ResetPassword from "./pages/home/log/reset-password";
import ResendMailValidation from "./pages/home/log/resend-mail-validation";
import ResetPasswordCode from "./pages/home/log/reset-password-code";
import ResetPasswordCodeModification from "./pages/home/log/reset-password-modification";
import UserSpace from "./pages/user/UserSpace";
import Plan from "./pages/user/plan";
import OfferSuccess from "./pages/offer/offer-success";
import OfferCancel from "./pages/offer/offer-cancel";

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login/" element={<Login/>} />
          <Route path="/signup/" element={<Signup />} />
          <Route path="/signup/mail-validation/" element={<MailValidation />} />
          <Route path="/signup/mail-validation/success-signup/" element={<SuccessSignup />} />
          <Route path="/resend-mail-validation" element={<ResendMailValidation />} />
          <Route path="/reset-password/" element={<ResetPassword />} />
          <Route path="/reset-password/code" element={<ResetPasswordCode />} />
          <Route path="/reset-password/code/modification" element={<ResetPasswordCodeModification />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/contact-us/mail-sent" element={<MailSent />} />
          <Route path="/contact-us/mail-not-sent" element={<MailNotSent />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms" element={<TermsOfUse />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/offer" element={<OffersPage />} />
          <Route path="/offer/success" element={<OfferSuccess />} />
          <Route path="/offer/cancel" element={<OfferCancel />} />

          <Route path="/user/" element={<UserSpace/>} />
          <Route path="/user/plan" element={<Plan/>} />
          <Route path="/user/plan/offer" element={<OffersPage/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
