import { React, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField  from "@mui/material/TextField";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { Box, Grid2, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Mail, Phone, Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import hostname from "../../../hostname";

const Signup = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    mail: "",
    phone: "",
    password1: "",
    password2: "",
  });

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${hostname}/api/v1/user/auth/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.message) {
          toast.success(data.message);
        }
        navigate("mail-validation");
      } else {
        // Gérer les erreurs d'authentification ici
        const data = await response.json(); // Obtenir les détails de l'erreur du backend
        if (data.message) {
          // Si le backend renvoie un message d'erreur, l'afficher sur le frontend
          toast.error(data.message);
        } else {
          // Si le message d'erreur n'est pas disponible, afficher un message générique
          toast.error("Erreur lors de l'inscription");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Grid2 container sx={{ minHeight: "100vh" }}>
        <Container
          container="true"
          maxWidth="sm"
          sx={{
            paddingTop: 5,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 5,
              borderRadius: "15px",
            }}
          >
            <Avatar sx={{ m: 1, backgroundColor: "#5271ff" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Signup
            </Typography>
            <Box
              component="form"
              onSubmit={handleSignup}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField 
              sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                id="name"
                label="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                autoComplete="name"
                autoFocus
              />
              <TextField 
              sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                id="name"
                label="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                autoComplete="lastname"
                autoFocus
              />
              <TextField 
              sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                id="email"
                label="mail"
                name="mail"
                value={formData.mail}
                onChange={handleChange}
                autoComplete="email"
                autoFocus
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <TextField 
              sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                id="phone"
                label="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                autoComplete="phone"
                autoFocus
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <TextField 
              sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                name="password1"
                label="password"
                type={showPassword1 ? "text" : "password"}
                id="password"
                value={formData.password1}
                onChange={handleChange}
                autoComplete="current-password"
                slotProps={{
                  input: {
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  },
                }}
              />
              <TextField 
              sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                name="password2"
                label="confirm-password"
                type={showPassword2 ? "text" : "password"}
                id="password"
                value={formData.password2}
                onChange={handleChange}
                autoComplete="current-password"
                slotProps={{
                  input: {
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                Signup
              </Button>
              <Grid2 container justifyContent="space-evenly">
                <Grid2 item>
                  <Link href="/login" variant="body2">
                    {"Login !"}
                  </Link>
                </Grid2>
              </Grid2>
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Footer />
    </div>
  );
};

export default Signup;
