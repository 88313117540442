import React from "react";
import Navbar from "../../components/main/navbar";
import Footer from "../../components/main/footer";
import refused from "../../images/payment-cancel.svg";
import { Button, Grid2, Typography } from "@mui/material";

const OfferCancel = () => {
  return (
    <div>
      <div sx={{ minHeight: "100vh" }}>
        <Navbar />

        <Grid2>
          <Grid2 container justifyContent="space-evenly" padding="50px">
            <Grid2 xs={6}>
              <img
                src={`${refused}`}
                height="300px"
                width="350px"
                alt="Success!"
              />
            </Grid2>
            <Grid2 xs={6} width="500px" alignContent="center" spacing={2}>
              <Typography
                variant="h3"
                fontSize="20px"
                color="#5271ff"
                flexWrap="wrap"
                display="flex"
              >
                {" "}
                Payment declined{" "}
              </Typography>

              <Typography variant="body2" color="#5271ff">
                {" "}
                Your payment has been declined. Please check your card
                information and try again.{" "}
              </Typography>

              <Button
                href="/offer"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                Try again
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </div>

      <Footer />
    </div>
  );
};

export default OfferCancel;
