import { React, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Grid2, IconButton } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import hostname from "../../../hostname";

const ResetPasswordCode = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    code: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${hostname}/api/v1/user/auth/verify-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.message) {
          toast.success(data.message);
        }
        navigate("modification");
      } else {
        const data = await response.json();
        if (data.message) {
          toast.error(data.message);
        } else {
          toast("Erreur d'authentification");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Grid2 container>
        <Container
          maxWidth="sm"
          sx={{
            paddingTop: 15,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
              borderRadius: "15px",
            }}
          >
            <Avatar sx={{ m: 2, backgroundColor: "#5271ff" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset password Code
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid2 container spacing={2}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: 13,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#5271ff",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#5271ff",
                    },
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="code"
                  label="code"
                  type={showPassword ? "text" : "code"}
                  id="code"
                  onChange={handleChange}
                  value={formData.code}
                  autoComplete="code"
                  slotProps={{
                    input: {
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    },
                  }}
                />

                <Grid2 item xs={12} textAlign="center">
                  <Typography sx={{ fontWeight: "light", fontStyle: "italic" }}>
                    Entrer le code qui vous a été envoyé par mail 📧 !
                  </Typography>
                </Grid2>
              </Grid2>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                Réinitialiser
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Footer />
      <ToastContainer theme="colored" />
    </div>
  );
};

export default ResetPasswordCode;
