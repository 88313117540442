import React from "react";
import Navbar from "../../components/user/navbar";
import Footer from "../../components/main/footer";
import {
  Grid2,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import Subscription from "../../images/subscription.png";
import { Link } from "react-router-dom";

const UserSpace = () => {
  return (
    <div>
      <Navbar />
      <Grid2
        container
        bgcolor="#f8f9fc"
        flexDirection="column"
        alignItems="center"
        flexWrap="wrap"
        padding="30px"
      >
        <Grid2 marginBottom="20px">
          <Typography variant="h3" fontWeight="700" fontSize="30px">
            SmartCandidate
          </Typography>
        </Grid2>
        <Grid2 container flexDirection="row" gap={5} padding="30px">
          <Card
            component={Link}
            to={"plan"}
            sx={{
              maxWidth: 300,
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="20px"
            >
              <CardMedia
                component="img"
                sx={{ height: 100, width: 100 }}
                image={`${Subscription}`}
              />
            </Box>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Plan
              </Typography>
              <Typography variant="body2" color="inherit">
                Easily monitor your remaining balance and track your usage with
                our intuitive plan management. Stay informed about your usage
                patterns and recharge your plan effortlessly to ensure
                uninterrupted access to our services.
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      <Footer />
    </div>
  );
};

export default UserSpace;
