import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Box, Grid2 } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hostname from "../../../hostname";

const Login = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mail: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${hostname}/api/v1/user/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();

        const accessToken = data.accessToken;
        localStorage.setItem("accessToken", accessToken);

        if (data.status === "actif") {
          navigate(`/user`);
        }
        if (data.status === "inactif" || data.status === null) {
          navigate(`/resend-mail-validation`);
        }
      } else {
        const data = await response.json();
        if (data.message) {
          toast.error(data.message);
        } else {
          toast("Erreur d'authentification");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Grid2 container sx={{ minHeight: "100vh" }}>
        <Container
          maxWidth="sm"
          sx={{
            paddingTop: 5,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 5,
              borderRadius: "15px",
            }}
          >
            <Avatar sx={{ m: 1, backgroundColor: "#5271ff" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box
              component="form"
              onSubmit={handleLogin}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                id="mail"
                label="mail"
                name="mail"
                value={formData.mail}
                onChange={handleChange}
                autoComplete="mail"
                autoFocus
              />
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: 13,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#5271ff",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5271ff",
                  },
                }}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                login
              </Button>
              <Grid2 container justifyContent="space-evenly">
                <Grid2 item xs>
                  <Link href="/reset-password" variant="body2">
                    Mot de passe oublié?
                  </Link>
                </Grid2>
                <Grid2 item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign up"}
                  </Link>
                </Grid2>
              </Grid2>
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Footer />
      <ToastContainer theme="colored" />
    </div>
  );
};

export default Login;
