import { React, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Grid2, IconButton, Link } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { Mail, Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import hostname from "../../../hostname";

const ResetPasswordCodeModification = () => {
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password1: "",
    password2: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${hostname}/api/v1/user/auth/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.message) {
          toast.success(data.message);
        }
        navigate("/login");
      } else {
        const data = await response.json();
        if (data.message) {
          toast.error(data.message);
        } else {
          toast("Erreur d'authentification");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Grid2 container>
        <Container
          maxWidth="sm"
          sx={{
            paddingTop: 15,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
              borderRadius: "15px",
            }}
          >
            <Avatar sx={{ m: 2, backgroundColor: "#5271ff" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset password Code
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid2 container spacing={2}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: 13,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#5271ff",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#5271ff",
                    },
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="mail"
                  name="mail"
                  value={formData.mail}
                  onChange={handleChange}
                  autoComplete="email"
                  autoFocus
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: 13,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#5271ff",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#5271ff",
                    },
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="password1"
                  label="password"
                  type={showPassword1 ? "text" : "password"}
                  id="password"
                  value={formData.password1}
                  onChange={handleChange}
                  autoComplete="current-password"
                  slotProps={{
                    input: {
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword1}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    },
                  }}
                />
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: 13,
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#5271ff",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#5271ff",
                    },
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="password2"
                  label="confirm-password"
                  type={showPassword2 ? "text" : "password"}
                  id="password"
                  value={formData.password2}
                  onChange={handleChange}
                  autoComplete="current-password"
                  slotProps={{
                    input: {
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    },
                  }}
                />

                <Grid2 item xs={12} textAlign="center">
                  <Typography sx={{ fontWeight: "light", fontStyle: "italic" }}>
                    Enter your new password !
                  </Typography>
                </Grid2>
              </Grid2>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#5271ff",
                  "&:hover": { backgroundColor: "#004aad" },
                }}
              >
                Réinitialiser
              </Button>
              <Grid2 item>
                <Link href="/reset-password" variant="body2">
                  {"Renvoyez le code"}
                </Link>
              </Grid2>
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Footer />
      <ToastContainer theme="colored" />
    </div>
  );
};

export default ResetPasswordCodeModification;
