import React from "react";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { Box, Grid2, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }

  return (
    <div>
      <Navbar />

      <section>
        <Grid2
          container
          bgcolor="#f8f9fc"
          flexDirection="column"
          alignItems="center"
          flexWrap="wrap"
          padding="30px"
        >
          <Typography variant="h3" fontWeight="700" fontSize="30px">
            Terms of Use
          </Typography>
          <Grid2
            container
            gap={2}
            flexDirection="column"
            justifyContent="center"
            maxWidth="850px"
          >
            <Grid2
              container
              p={2}
              px={6}
              gap={3}
              flexDirection="column"
              justifyContent="center"
              maxWidth="850px"
            >
              <Box>
                <Typography variant="h4">Table of Contents</Typography>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Acceptance-of-Terms"
                      variant="body2"
                      onClick={() => scrollToSection("Acceptance-of-Terms")}
                    >
                      Acceptance of Terms
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Collected-information"
                      variant="body2"
                      onClick={() => scrollToSection("Collected-information")}
                    >
                      Information we collect
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Use-information"
                      variant="body2"
                      onClick={() => scrollToSection("Use-information")}
                    >
                      How we use your information
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Data-sharing"
                      variant="body2"
                      onClick={() => scrollToSection("Data-sharing")}
                    >
                      Data Sharing
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Data-security"
                      variant="body2"
                      onClick={() => scrollToSection("Data-security")}
                    >
                      Data Security
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Data-retention"
                      variant="body2"
                      onClick={() => scrollToSection("Data-retention")}
                    >
                      Data Retention
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Your-rights"
                      variant="body2"
                      onClick={() => scrollToSection("Your-rights")}
                    >
                      Your rights
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Cookies-policy"
                      variant="body2"
                      onClick={() => scrollToSection("Cookies-policy")}
                    >
                      Cookies Policy
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Services-Provided"
                      variant="body2"
                      onClick={() => scrollToSection("Services-Provided")}
                    >
                      Services Provided
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#User-Registration-and-Account"
                      variant="body2"
                      onClick={() =>
                        scrollToSection("User-Registration-and-Account")
                      }
                    >
                      User Registration and Account
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#User-Responsibilities"
                      variant="body2"
                      onClick={() => scrollToSection("User-Responsibilities")}
                    >
                      User Responsibilities
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Intellectual-Property-Rights"
                      variant="body2"
                      onClick={() =>
                        scrollToSection("Intellectual-Property-Rights")
                      }
                    >
                      Intellectual Property Rights
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#User-Generated-Content"
                      variant="body2"
                      onClick={() => scrollToSection("User-Generated-Content")}
                    >
                      User-Generated Content
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Subscription-and-Payment"
                      variant="body2"
                      onClick={() =>
                        scrollToSection("Subscription-and-Payment")
                      }
                    >
                      Subscription and Payment
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to={"#Refund-Policy"}
                      variant="body2"
                      onClick={() => scrollToSection("Refund-Policy")}
                    >
                      Refund Policy
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Limitation-of-Liability"
                      variant="body2"
                      onClick={() => scrollToSection("Limitation-of-Liability")}
                    >
                      Limitation of Liability
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Termination-of-Services"
                      variant="body2"
                      onClick={() => scrollToSection("Termination-of-Services")}
                    >
                      Termination of Services
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Changes-to-Terms"
                      variant="body2"
                      onClick={() => scrollToSection("Changes-to-Terms")}
                    >
                      Changes to Terms
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{ color: "black" }}
                      component={Link}
                      to="#Contact-Us"
                      variant="body2"
                      onClick={() => scrollToSection("Contact-Us")}
                    >
                      Contact Us
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Grid2>
            <Grid2
              display="flex"
              container
              p={2}
              px={6}
              gap={3}
              flexDirection="column"
              justifyContent="center"
              maxWidth="850px"
            >
              <Grid2>
                {" "}
                <Box id="Acceptance-of-Terms">
                  <Typography variant="h5">1. Acceptance of Terms</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    By accessing our website or using our services, you agree to
                    these Terms of Use, our Privacy Policy, and any additional
                    terms and conditions that may apply to specific sections of
                    the site or to products and services available through the
                    site.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                {" "}
                <Box id="Collected-information">
                  <Typography variant="h5">
                    2. Information we collect
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    To use SmartCandidate efficiently, we collect essential
                    information, including your first and last name, email
                    address, phone number, and password (securely stored in
                    hashed format). For account authentication and security, we
                    process reset password and email verification codes with
                    their expiration timestamps. Additionally, we track account
                    usage data, such as your status, number of uses, and
                    subscription pricing plan. When generating personalized
                    cover letters, we handle the document data you provide.
                    Cookies are used solely to verify user identity during login
                    sessions and are deleted immediately upon logout.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                {" "}
                <Box id="Use-information">
                  <Typography variant="h5">
                    3. How we use your information
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    We use your data to generate personalized cover letters,
                    improve our services through troubleshooting and user
                    support, and enhance the overall user experience.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                {" "}
                <Box id="Data-sharing">
                  <Typography variant="h5">4. Data Sharing</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    Your data is never sold or rented. It is shared only with
                    trusted service providers, such as Firebase Storage for
                    secure file hosting, or as required by law in response to
                    valid legal requests.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                {" "}
                <Box id="Data-security">
                  <Typography variant="h5">5. Data Security</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    To protect your data, we implement strong security measures,
                    including encryption during storage and transmission. Access
                    to your personal data is restricted to authorized personnel
                    only.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                {" "}
                <Box id="Data-retention">
                  <Typography variant="h5">6. Data Retention</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    We retain your information only as long as necessary to
                    provide our services or comply with legal obligations. You
                    may request the deletion of your data at any time.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                {" "}
                <Box id="Your-rights">
                  <Typography variant="h5">7. Your Rights</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    You have the right to access, correct, or delete your data,
                    and to withdraw consent for data processing. To exercise
                    these rights, contact us at smartcandidate.ai@gmail.com.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2>
                {" "}
                <Box id="Cookies-policy">
                  <Typography variant="h5">8. Cookies Policy</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    Cookies are used solely to enhance your browsing experience,
                    and you can manage their preferences through your browser
                    settings.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Services-Provided">
                  <Typography variant="h5">9. Services Provided</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    SmartCandidate provides tools for generating personalized
                    cover letters. Our services include tailored document
                    generation based on job descriptions, user experience, and
                    language preferences.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="User-Registration-and-Account">
                  <Typography variant="h5">
                    10. User Registration and Account
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    To use certain features of SmartCandidate, you may be
                    required to register an account. You agree to provide
                    accurate and up-to-date information and to keep your login
                    credentials secure. You are responsible for all activities
                    that occur under your account.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="User-Responsibilities">
                  <Typography variant="h5">
                    11. User Responsibilities
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    You agree to use SmartCandidate only for lawful purposes.
                    You may not: Misuse the platform for any illegal activities.
                    Use the generated content for any fraudulent purposes.
                    Attempt to interfere with or harm our platform, other users,
                    or our servers.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Intellectual-Property-Rights">
                  <Typography variant="h5">
                    12. Intellectual Property Rights
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    All content, including but not limited to text, graphics,
                    software, and other materials available on SmartCandidate,
                    is owned or licensed by us and protected by intellectual
                    property laws. You may not reproduce, distribute, or create
                    derivative works from our content without our explicit
                    permission.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="User-Generated-Content">
                  <Typography variant="h5">
                    13. User-Generated Content
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    You retain ownership of any content you provide, such as
                    personal information for CVs or job description details. By
                    providing this content, you grant SmartCandidate a license
                    to use it solely for the purpose of generating documents as
                    requested.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Subscription-and-Payment">
                  <Typography variant="h5">
                    14. Subscription and Payment
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    Certain features of SmartCandidate may require payment.
                    Payment terms, including fees and billing cycles, will be
                    provided upon signup. By subscribing, you agree to the
                    payment terms and authorize us to charge your payment method
                    for the agreed amount.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Refund-Policy">
                  <Typography variant="h5">15. Refund Policy</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    Refunds for payment will be handled on a case-by-case basis.
                    Please refer to our Refund Policy or contact our support
                    team if you have questions regarding refunds.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Limitation-of-Liability">
                  <Typography variant="h5">
                    16. Limitation of Liability
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    SmartCandidate provides its services "as is" without any
                    warranties, expressed or implied. We do not guarantee the
                    accuracy, completeness, or suitability of the documents
                    generated. SmartCandidate is not responsible for any
                    employment decisions based on documents created using our
                    platform.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Termination-of-Services">
                  <Typography variant="h5">
                    17. Termination of Services
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    We reserve the right to suspend or terminate your access to
                    SmartCandidate if you violate these Terms of Use or engage
                    in activities that harm our services, reputation, or other
                    users.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Changes-to-Terms">
                  <Typography variant="h5">18. Changes to Terms</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    We may update these Terms of Use from time to time. Any
                    changes will be posted on this page, and continued use of
                    the platform after changes have been made constitutes
                    acceptance of those changes.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 maxWidth="850px">
                {" "}
                <Box id="Contact-Us">
                  <Typography variant="h5">19. Contact Us</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    If you have any questions about these Terms of Use, please
                    contact us at smartcandidate.ai@gmail.com.
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </section>

      <Footer />
    </div>
  );
};

export default TermsOfUse;
