import React from "react";
import Navbar from "../../../components/main/navbar";
import Footer from "../../../components/main/footer";
import { Typography, Grid2 } from "@mui/material";
import Mission from "../../../images/mission.svg";
import Team from "../../../images/team.svg";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <Grid2  bgcolor="#f8f9fc">
        <Grid2 container justifyContent="space-evenly" p={2} px={6}>
          <Grid2 xs={6}>
            <img
              src={`${Mission}`}
              height="300px"
              width="350px"
              alt="Success!"
            />
          </Grid2>
          <Grid2 xs={6} width="500px" alignContent="center" spacing={2}>
            <Typography
              variant="h3"
              fontSize="20px"
              color="#5271ff"
              flexWrap="wrap"
              display="flex"
            >
              {" "}
              Our mission{" "}
            </Typography>

            <Typography
              variant="body2"
              color="#5271ff"
              maxWidth="500px"
              fontWeight="700"
              marginTop="20px"
            >
              {" "}
              SmartCandidate is an intelligent application dedicated to creating
              custom cover letters tailored to the requirements of each
              position. It helps candidates personalize their applications based
              on their experiences and recruiter expectations. With
              SmartCandidate, prepare perfectly aligned application documents
              and boost your chances of success. Our platform adapts your
              profile to match specific job descriptions, saving you time and
              maximizing impact. Stand out effortlessly and present your best
              self to potential employers.{" "}
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 container justifyContent="space-evenly" p={2} px={6}>
          <Grid2 xs={6} width="500px" alignContent="center" spacing={2}>
            <Typography
              variant="h3"
              fontSize="20px"
              color="#5271ff"
              flexWrap="wrap"
              display="flex"
            >
              {" "}
              Our Team{" "}
            </Typography>

            <Typography
              variant="body2"
              color="#5271ff"
              maxWidth="500px"
              fontWeight="700"
              marginTop="20px"
            >
              {" "}
              Our team is dedicated to building a powerful and user-focused
              solution that supports candidates at every step of their job
              search. Comprised of passionate professionals in technology,
              recruitment, and design, we are committed to simplifying and
              enhancing the application process. We believe in continuous
              improvement, driven by the feedback of our users, to make
              SmartCandidate the most effective tool for crafting standout
              applications. With a shared goal of empowering job seekers, we
              work tirelessly to provide an intelligent, intuitive, and
              impactful experience.{" "}
            </Typography>
          </Grid2>
          <Grid2 xs={6}>
            <img src={`${Team}`} height="300px" width="350px" alt="Success!" />
          </Grid2>
        </Grid2>
      </Grid2>

      <Footer />
    </div>
  );
};

export default AboutUs;
